import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = [
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/28-july-2/pic1.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/28-july-2/pic2.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/28-july-2/pic3.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/28-july-2/pic4.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/28-july-2/pic5.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/28-july-2/pic6.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/28-july-2/pic7.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/28-july-2/pic8.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/28-july-2/pic9.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/28-july-2/pic10.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/28-july-2/pic11.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/28-july-2/pic12.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/28-july-2/pic13.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/28-july-2/pic14.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/28-july-2/pic15.JPG",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/28-july-2/pic16.JPG",
];

export default function PrimaryInvestiture2023() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">Primary Investiture Ceremony 2023-24</h1>

        <p>
          <strong>
            Leadership is the capacity to transform vision into reality.
          </strong>
          <br />
          <br />
          To inculcate leadership qualities in the students,the Primary
          Investiture ceremony was conducted at Chinmaya Vidyalaya,New Delhi on
          25.7.2023.
          <br />
          <br />
          The newly elected council members were conferred with badges and
          sashes by the Principal Ms.Archana Soni and Headmistress Ms.Archana
          Gaur.
          <br />
          <br />
          The council thereafter took an oath to discharge their duties to the
          best of their abilities.It was a moment of immense pride for the
          Vidyalaya.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
